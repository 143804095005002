// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // new
    production: false,
    // apiUrl: 'http://192.168.3.51/api/v1',
    // fileUrlPath: 'http://keyi.xdrlgroup.com',
    // usFileUrl: 'http://192.168.3.51/storage/',
    // origin: 'http://192.168.3.51',
    // wsOrigin: 'ws://192.168.3.51:2000',

    //测试带域名
    // apiUrl: 'https://test.yingyanxt.com/api/v1',
    // fileUrlPath: 'http://keyi.xdrlgroup.com',
    // usFileUrl: 'https://test.yingyanxt.com/storage/',
    // origin: 'https://test.yingyanxt.com',
    // wsOrigin: 'ws://121.196.179.68:8085',

    // 正式
    apiUrl: 'http://121.196.179.68/api/v1',
    fileUrlPath: 'http://keyi.xdrlgroup.com',
    usFileUrl: 'http://121.196.179.68/storage/',
    origin: 'http://121.196.179.68',
    wsOrigin: 'ws://121.196.179.68:8083',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
